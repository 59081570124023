/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { Link } from 'gatsby';
import { Button } from '@chakra-ui/core';

import Layout from '../components/display/layout';
import SEO from '../components/seo';

import { Navigation, ErrorText } from '../components/textfiles/maintexts';

const NotFoundPage = () => (
  <Layout>
    <SEO title={ErrorText.title} />
    <h1>{ErrorText.title}</h1>
    <p>{ErrorText.subtitle}</p>
    <Link to="/">
      <Button w={150} m={1} variantColor="red">
        {Navigation.goBack}
      </Button>
    </Link>
  </Layout>
);

export default NotFoundPage;
